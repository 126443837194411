import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledInput = styled.input`
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  margin: ${(props) => props.margin};
  padding: 0px 10px;
  height: ${(props) => props.height || "38px"};
  border: 1px solid ${(props) => (props.error ? "#F6412D" : "#c0c0c0")};
  border-radius: 8px;
  background-color: ${(props) => props.backgroundColor};
  outline: none;
  font-family: "Poppins", sans-serif;
  color: #4f4f4f !important;
  transition-duration: 0.8s;
  @media (max-width: 420px) {
    width: ${(props) => props.widthXs};
    margin: ${(props) => props.marginXs};
  }
  :disabled {
    color: #4f4f4f !important;
    background-color: #fff !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
    border: 1px solid #c0c0c0 !important;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
  }
`;

function Input({
  placeholder,
  onChange,
  onKeyPress,
  onKeyDown,
  onFocus,
  onBlur,
  value,
  type,
  width,
  widthXs,
  marginXs,
  maxWidth,
  margin,
  id,
  error,
  autoComplete,
  backgroundColor,
  disabled,
  externalRef,
  height,
  checked,
  name,
  className,
  maxLength,
  minWidth,
}) {
  return (
    <StyledInput
      ref={externalRef}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      autoComplete={autoComplete}
      value={value}
      type={type}
      width={width}
      maxWidth={maxWidth}
      widthXs={widthXs}
      marginXs={marginXs}
      margin={margin}
      id={id}
      error={error}
      backgroundColor={backgroundColor}
      disabled={disabled}
      height={height}
      checked={checked}
      name={name}
      className={className}
      maxLength={maxLength}
      minWidth={minWidth}
    />
  );
}

Input.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
  marginXs: PropTypes.string,
  widthXs: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  checked: PropTypes.bool,
  externalRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  name: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  minWidth: PropTypes.string,
};

Input.defaultProps = {
  placeholder: "",
  width: "100%",
  widthXs: undefined,
  maxWidth: "100%",
  margin: undefined,
  marginXs: undefined,
  value: undefined,
  type: "text",
  onChange: undefined,
  onKeyPress: undefined,
  onKeyDown: undefined,
  onFocus: undefined,
  onBlur: undefined,
  error: undefined,
  autoComplete: undefined,
  backgroundColor: undefined,
  disabled: false,
  externalRef: undefined,
  id: undefined,
  height: undefined,
  checked: undefined,
  name: undefined,
  className: undefined,
  maxLength: undefined,
  minWidth: undefined,
};

export default Input;
