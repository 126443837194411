import { create } from 'zustand'

const useWarehouseCartStore = create((set) => ({
  warehouseCart: {isOpenModal: false, items: [], commerceId: null, isOpenModalPromotor: false},

  loadInitialData: (commerceId) => set(
    ({warehouseCart}) => {
      return {
        warehouseCart : {
          ...warehouseCart, 
          items: JSON.parse(localStorage.getItem(`warehouseCartItems_${commerceId}`)) || [],
          commerceId
        }
      }
    }
  ),

  updateWarehouseCartItem: (product, quantity) => set(
    (state) => {
      const { warehouseCart } = state
      let items = []
      if (warehouseCart.items.find(i => i.id === product.id)) {
        items = warehouseCart.items.map(warehouseCartProduct => {
          if (warehouseCartProduct.id === product.id) {
            return {
              ...warehouseCartProduct,
              quantity,
            }
          }
          return { ...warehouseCartProduct}
        })
      } else {
        items = [{...product, quantity}, ...warehouseCart.items ]
      }

      localStorage.setItem(`warehouseCartItems_${warehouseCart.commerceId}`, JSON.stringify(items))
      return { warehouseCart: {...warehouseCart, items} }
    }
  ),

  removeWarehouseCartItem: (item) => set(
    (state) => {
      const { warehouseCart } = state
      if (!warehouseCart.items.find(i => i.id === item.id)) {
        return state
      }
      const items = [
        ...warehouseCart.items.filter(i => i.id !== item.id)
      ]
      localStorage.setItem(`warehouseCartItems_${warehouseCart.commerceId}`, JSON.stringify(items))
      return { warehouseCart: {...warehouseCart, items} }
    }
  ),
  
  incrementWarehouseCartItem: (product, quantity) => set(
    (state) => {
      const { warehouseCart } = state
      let items = warehouseCart.items.map(warehouseCartProduct => {
        if (warehouseCartProduct.id === product.id) {
          return {
            ...warehouseCartProduct,
            quantity: warehouseCartProduct.quantity + quantity < 1 ? 1 : warehouseCartProduct.quantity + quantity,
          }
        }
        return warehouseCartProduct
      })
      if (!warehouseCart.items.find(i => i.id === product.id) && quantity > 0) {
        items = [
          { ...product, quantity },
          ...items,
        ]
      }

      localStorage.setItem(`warehouseCartItems_${warehouseCart.commerceId}`, JSON.stringify(items))
      return { warehouseCart: {...warehouseCart, items} }
    }
  ),

  clearWarehouseCart: () => set(
    ({warehouseCart}) => {
      localStorage.setItem(`warehouseCartItems_${warehouseCart.commerceId}`, JSON.stringify([]))
      return { warehouseCart: {...warehouseCart, items: []} }
  }),

  toggleWarehouseCartModal: () => set(
    ({warehouseCart}) => {
      return { warehouseCart: {...warehouseCart, isOpenModal: !warehouseCart.isOpenModal} }
    }
  ),
  toggleWarehouseCartPromotorModal: () => set(
    ({warehouseCart}) => {
      return { warehouseCart: {...warehouseCart, isOpenModalPromotor: !warehouseCart.isOpenModalPromotor} }
    }
  ),
}))

export default useWarehouseCartStore