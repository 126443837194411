import React, { useRef } from "react";
import { MdCancel, MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Button from "./Button";
import QuantityButtons from "./QuantityButtons";
import VerticalQuantityButtons from "./VerticalQuantityButtons";
import { cartSelector, isUpdatingCartSelector } from "../ducks/shopping/selectors";
import { updateCart, removeOrden, updateCartInit } from "../ducks/shopping/actions";
import { getAmount } from "../utils";
import { tokenSelector, userSelector } from "../ducks/access/selectors";
import { isOpenCartModalSelector } from "../ducks/general/selectors";
import { openCartModal } from "../ducks/general/actions";
import AbsoluteLoader from "./AbsoluteLoader";
import { currencySymbol } from "../constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: 20,
    boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    zIndex: 999999999999,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  width: 768px;
  min-height: 400px;
  div.divider {
    min-height: 1px;
    background-color: #eaeaea;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: calc(100vh - 80px);
    width: calc(100vw - 20px);
  }
`;
const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  margin: ${(props) => props.margin};
  @media (max-width: 768px) {
    margin: ${(props) => props.marginSm};
  }
`;
const Value = styled.p`
  font-size: 16px;
  color: #3c434d;
  line-height: 0.95;
  text-align: center;
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: 40px 30fr 17.5fr 17.5fr 17.5fr 17.5fr;
  margin-bottom: 10px;
  align-items: center;
  justify-items: center;
  @media (max-width: 768px) {
    padding: 0px;
    padding-bottom: 20px;
    max-height: calc(100vh - 40px);
    width: calc(100vw - 20px);
    grid-template-columns: 30px 1fr 40px;
  }
`;
const Content = styled.div`
  flex: 1;
`;
const Product = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    width: 69px;
    height: 57px;
    object-fit: cover;
    border-radius: 8px;
  }
  div {
    margin-left: 10px;
  }
  p.name {
    font-size: 12px;
    font-weight: bold;
    color: #404040;
  }
  p.variant {
    font-weight: 300;
    font-size: 12px;
    color: #404040;
  }
`;
const NoResults = styled.p`
  text-align: center;
  margin: 30px;
`;
const ContainerCar = styled.div`
  padding: 5px 0px;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
`;
const ContainerBtnPayment = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
`;
const ContainerScroll = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 180px);
  padding-top: 5px;
  padding: 10px;
  @media (max-width: 768px) {
    padding: 0px;
    padding-top: 5px;
  }
`;
Modal.setAppElement("#root");

export default function CartModal() {
  const history = useHistory();
  const isRemoveOrden = useSelector((state) => state.shoppingStore.idOrden);
  const user = useSelector(userSelector);
  const cart = useSelector(cartSelector);
  const isUpdatingCart = useSelector(isUpdatingCartSelector);
  const token = useSelector(tokenSelector);
  const isOpen = useSelector(isOpenCartModalSelector);
  const dispatch = useDispatch();
  const timeout = useRef();
  
  React.useEffect(() => {
    if (isRemoveOrden) {
      const newCart = JSON.parse(JSON.stringify(cart));
      const productIndex = newCart.findIndex((item) => item.idStore === isRemoveOrden);
      newCart.splice(productIndex, 1);
      dispatch(updateCart(newCart));
      dispatch(removeOrden());
    }
  }, [isRemoveOrden, dispatch, cart]);

  const handleClickMore = (productIndex, commerceIndex) => {
    // Google Analytics
    if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
    window.gtag('event', 'click', {
      'event_category': 'carrito de compra',
      'event_label': 'Aumentar cantidad de producto en carrito'
    });
    }

    const newCart = JSON.parse(JSON.stringify(cart));
    const { stock } = newCart[commerceIndex].ordenItems[productIndex];
    if (newCart[commerceIndex].ordenItems[productIndex].quantity < stock.stock) {
      newCart[commerceIndex].ordenItems[productIndex].quantity += 1;
      dispatch(updateCart(newCart));
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        const payload = {
          items: [],
        };
        newCart.forEach((storeItem) => {
          storeItem.ordenItems.forEach((item) => {
            payload.items.push({
              quantity: item.quantity,
              stock: item.stock.id,
            });
          });
        });
        dispatch(updateCartInit(payload));
      }, 500);
    }else{
      toast.info("Sin stock")
    }
  };

  const handleClickLess = (productIndex, commerceIndex) => {
    // Google Analytics
    if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
    window.gtag('event', 'click', {
      'event_category': 'carrito de compra',
      'event_label': 'Disminuir cantidad de producto en carrito'
    });
  }

    const newCart = JSON.parse(JSON.stringify(cart));
    if (newCart[commerceIndex].ordenItems[productIndex].quantity > 1) {
      newCart[commerceIndex].ordenItems[productIndex].quantity -= 1;
      dispatch(updateCart(newCart));
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        const payload = {
          items: [],
        };
        newCart.forEach((storeItem) => {
          storeItem.ordenItems.forEach((item) => {
            payload.items.push({
              quantity: item.quantity,
              stock: item.stock.id,
            });
          });
        });
        dispatch(updateCartInit(payload));
      }, 500);
    }
  };

  const handleDeleteProduct = (productIndex, commerceIndex) => {
     // Google Analytics
     if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
      window.gtag('event', 'click', {
        'event_category': 'carrito de compra',
        'event_label': 'Eliminar producto del carrito de compra'
      });
    }

    const newCart = JSON.parse(JSON.stringify(cart));
    newCart[commerceIndex].ordenItems.splice(productIndex, 1);
    if (newCart[commerceIndex].ordenItems.length === 0) {
      newCart.splice(commerceIndex, 1);
    }

    if (!user?.id) {
      dispatch(updateCart(newCart));
      return
    }

    const payload = {
      items: [],
    };
    newCart.forEach((storeItem) => {
      storeItem.ordenItems.forEach((item) => {
        payload.items.push({
          quantity: item.quantity,
          stock: item.stock.id,
        });
      });
    });
    dispatch(updateCartInit(payload));
  };

  const handleClickRouter = () => () => {
    // Google Analytics
    if(process.env.REACT_APP_ENABLE_ANALYTICS_EVENTS==="true") {
    window.gtag('event', 'click', {
      'event_category': 'carrito de compra',
      'event_label': 'Boton pagar en carrito'
    });
  }
  
    if (cart && cart.length) {
      dispatch(openCartModal(false));
      if (token === undefined) history.push("/login", { checkout: true, idStore: 1 });
      else {
        history.push(`/contact-information/`, 1);
      }
    }
  };

  const handleClose = () => {
    dispatch(openCartModal(false));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Detalle del producto"
    >
      <AbsoluteLoader visible={isUpdatingCart && !!user?.id} opacity={0.8} className="rounded-[20px]">
      <Container>
        <CloseButton onClick={handleClose}>
          <MdCancel className="opacity" size={29} color="#b1bbcd" />
        </CloseButton>
        <Title marginSm="0px 0px 10px 10px" margin="0px 0px 15px 20px">
          Carrito de compra
        </Title>
        <div className="divider" />
        <Content>
          <ContainerScroll>
            {Array.isArray(cart) && cart.length ? (
              cart.map((item, commerceIndex) => {
                if (Array.isArray(item.ordenItems) && item.ordenItems.length > 0) {
                  return (
                    <ContainerCar key={commerceIndex}>
                      <Title margin="0px 0px 10px 10px">
                        {item && item.commerce && item.commerce.name}
                      </Title>
                      {item.ordenItems.map((itemProduct, productIndex) => {
                        return (
                          <div key={productIndex}>
                            <Table>
                              <MdDelete
                                onClick={() => handleDeleteProduct(productIndex, commerceIndex)}
                                size={26}
                                className="opacity"
                                color="#b1bbcd"
                              />
                              <Product>
                                <img
                                  src={
                                    itemProduct &&
                                    itemProduct.product &&
                                    itemProduct.product.images.length &&
                                    itemProduct.product.images[0]
                                  }
                                  alt="product"
                                />
                                <div>
                                  <p className="name">
                                    {itemProduct.product &&
                                      itemProduct.product.variant &&
                                      itemProduct.product.variant.product.name}
                                  </p>
                                  <p className="variant">
                                    {itemProduct.product && itemProduct.product.name}
                                  </p>
                                </div>
                              </Product>
                              <div className="hidden-md-and-up row-cont flex-end-row">
                                <VerticalQuantityButtons
                                  onClickMore={() => handleClickMore(productIndex, commerceIndex)}
                                  onClickLess={() => handleClickLess(productIndex, commerceIndex)}
                                  quantity={itemProduct.quantity}
                                />
                              </div>
                              <div className="hidden-sm">
                                <QuantityButtons
                                  onClickMore={() => handleClickMore(productIndex, commerceIndex)}
                                  onClickLess={() => handleClickLess(productIndex, commerceIndex)}
                                  quantity={itemProduct.quantity}
                                />
                              </div>
                              <Value className="hidden-sm">
                                {item.commerce && item.commerce.name}
                              </Value>
                              <Value className="hidden-sm">
                                {currencySymbol}
                                {itemProduct?.stock &&
                                  getAmount(itemProduct?.stock?.price).format("0,0.00")}
                              </Value>
                              <Value className="hidden-sm">
                                {currencySymbol}
                                {itemProduct?.stock &&
                                  getAmount(
                                    parseInt(itemProduct?.total, 10)
                                  ).format("0,0.00")
                                }                              
                              </Value>
                            </Table>
                          </div>
                        );
                      })}
                    </ContainerCar>
                  );
                }
                return null;
              })
            ) : (
              <NoResults>No tienes productos en tu carrito</NoResults>
            )}
          </ContainerScroll>
        </Content>
        <div className="divider" />
        <div className="row-cont space-between">
          <div>
            <Button
              width="200px"
              widthSm="140px"
              paddingSm="0px 10px"
              backgroundColor="#a0a0a0"
              marginSm="10px 0px 0px 10px"
              margin="20px 0px 0px 20px"
              onClick={handleClose}
            >
              Seguir comprando
            </Button>
          </div>
          <ContainerBtnPayment>
            {/* {
              Array.isArray(cart) && cart.length ? 
            } */}
            <Button
              // outline={!cart.length === 0 ? undefined : true}
              backgroundColor={cart.length === 0 ? "#a0a0a0" : ""}
              width="200px"
              widthSm="140px"
              onClick={handleClickRouter()}
            >
              Pagar
            </Button>
          </ContainerBtnPayment>
        </div>
      </Container>
      </AbsoluteLoader>
    </Modal>
  );
}