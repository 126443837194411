import React from "react";
import { MdCancel, MdDelete } from "react-icons/md";
import styled from "styled-components";
import Modal from "react-modal";
import Button from "../Button";
import QuantityButtons from "../QuantityButtons";
import VerticalQuantityButtons from "../VerticalQuantityButtons";
import useWarehouseCartStore from "../../hooks/useWarehouseCartStore";
import { dangerColor } from "../../constants";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: 20,
    boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    zIndex: 999999999999,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100vw - 20px);
  div.divider {
    min-height: 1px;
    background-color: #eaeaea;
  };
  @media (min-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 768px;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  margin: ${(props) => props.margin};
  @media (max-width: 768px) {
    margin: ${(props) => props.marginSm};
  }
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  column-gap: 10px
`;
const NoResults = styled.p`
  text-align: center;
  margin: 30px;
`;
const ContainerCar = styled.div`
  padding: 5px 5px;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin-bottom: 10px;
`;
const ContainerScroll = styled.div`
  overflow: auto;
  max-height: calc(90vh - 180px);
  padding: 10px;
  @media (max-width: 768px) {
    padding: 5px;
    max-height: calc(90vh - 180px);
  }
`;
Modal.setAppElement("#root");

export default function WarehouseCartModal() {

  const { 
    warehouseCart,
    toggleWarehouseCartModal,
    removeWarehouseCartItem,
    incrementWarehouseCartItem,
    toggleWarehouseCartPromotorModal
  } = useWarehouseCartStore();

  const handleClose = () => {
    toggleWarehouseCartModal()
  };

  const handleClickWarehouseRequestOrderButton = () => {
    toggleWarehouseCartModal()
    toggleWarehouseCartPromotorModal()
  };
  

  return (
    <Modal
      isOpen={warehouseCart.isOpenModal}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Detalle del producto"
    >
        <Container>
          <CloseButton onClick={handleClose}>
            <MdCancel className="opacity" size={29} color="#b1bbcd" />
          </CloseButton>
          <Title marginSm="0px 0px 10px 10px" margin="0px 0px 15px 20px">
            Carrito de productos
          </Title>
          <div className="divider" />
            <ContainerScroll>
              {warehouseCart.items.map((productItem, productItemIndex) => {
                return (
                  <ContainerCar key={productItemIndex}>
                      <Table className="w-fuk">
                          <img
                            className="w-20 h-57 sm:w-[69px] sm:h-[57px] object-cover rounded-[8px]"
                            src={
                              productItem.image
                            }
                            alt="imagen del producto"
                          />
                          <div className="featured-text">
                            { productItem.sku }
                          </div>
                        <div className="hidden-md-and-up row-cont flex-end-row">
                          <VerticalQuantityButtons
                            onClickMore={() => incrementWarehouseCartItem(productItem, 1)}
                            onClickLess={() => incrementWarehouseCartItem(productItem, -1)}
                            quantity={productItem.quantity}
                          />
                        </div>
                        <div className="hidden-sm">
                          <QuantityButtons
                            onClickMore={() => incrementWarehouseCartItem(productItem, 1)}
                            onClickLess={() => incrementWarehouseCartItem(productItem, -1)}
                            quantity={productItem.quantity}
                          />
                        </div>
                        <MdDelete
                          onClick={() => removeWarehouseCartItem(productItem)}
                          size={26}
                          className="opacity"
                          color={dangerColor}
                        />
                      </Table>
                  </ContainerCar>
                );
              })}
              {
                warehouseCart.items.length === 0 && (
                  <NoResults>No tienes productos en tu carrito</NoResults>
                )
              }
            </ContainerScroll>
          <div className="divider" />
          <div className="flex justify-between pt-[10px] pr-[10px] pl-[10px] md:pt-[20px]">
            <Button
              width="200px"
              widthSm="140px"
              onClick={handleClose}
            >
              Volver
            </Button>
            <Button
              // outline={!cart.length === 0 ? undefined : true}
              backgroundColor={!warehouseCart.items.length ? "#a0a0a0" : ""}
              width="200px"
              widthSm="140px"
              onClick={handleClickWarehouseRequestOrderButton}
              disabled={!warehouseCart.items.length}
            >
              Solicitar
            </Button>
          </div>
        </Container>
    </Modal>
  );
}
