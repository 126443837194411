import API from "../api";

export function getWarehouses(storeId, params) {
    return API.get(`/api/commerces/${storeId}/warehouse-workers`, { params })
}

export function updateWarehouser(id, data) {
    return API.put(`/api/warehousers/${id}`, { ...data })
}

export function createWarehouser(data) {
    return API.post(`/api/warehousers`, { ...data })
} 

export function requestWarehouserOrder(commerceId, data) {
    return API.post(`/api/commerces/${commerceId}/warehouse-orders`, { ...data })
}

export function getStoresWithStock(params) {
    return API.get(`/api/stores-with-stock`, { params })
}

export function getTokenResource(params) {
    return API.post(`/api/users/${params.userId}/tokens/resources`)
}

export function createScanLog(params) {
    return API.post(`/api/scan-logs`, { ...params })
}