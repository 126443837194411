import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";
import { customToast } from "../../utils";
import { openInfoModal } from "../general/actions";
import { cartSelector } from "../shopping/selectors";
import { getCartInit, updateCart, } from "../shopping/actions";
import {
  UPDATE_CART_ERROR,
  UPDATE_CART_SUCCESS,
} from "../shopping/types";

export function* registerCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/api/users", payload);
    if (result.status === 200 || result.status === 201) {
      if (typeof callback === "function") callback(result);
      yield put({ type: types.REGISTER_COMPLETE });
    } else {
      yield put({ type: types.REGISTER_ERROR });
    }
  } catch (error) {
    yield put({ type: types.REGISTER_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* changePasswordCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/api/change-passwords", payload);
    if (result.status === 200 || result.status === 201) {
      if (typeof callback === "function") callback(result);
      yield put({ type: types.CHANGE_PASSWORD_COMPLETE });
    } else {
      yield put({ type: types.CHANGE_PASSWORD_ERROR });
    }
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.oldPassword
    ) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: `Contraseña anterior ${error.response.data.errors.oldPassword}`,
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
  }
}

export function* recoverPasswordCall({ payload, callback }) {
  try {
    const result = yield call(
      API.post,
      "/api/recovery-passwords-verifications",
      payload
    );
    if (result.status === 200 || result.status === 201) {
      if (typeof callback === "function") callback(result);
      yield put({ type: types.RECOVER_PASSWORD_COMPLETE });
    } else {
      yield put({ type: types.RECOVER_PASSWORD_ERROR });
    }
  } catch (error) {
    yield put({ type: types.RECOVER_PASSWORD_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    ) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "En enlace no es válido o ha expirado",
        })
      );
    }
  }
}

export function* resetPasswordCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/api/recovery-passwords", payload);
    if (result.status === 200 || result.status === 201) {
      if (typeof callback === "function") callback(result);
      yield put({ type: types.RESET_PASSWORD_COMPLETE });
    } else {
      yield put({ type: types.RESET_PASSWORD_ERROR });
    }
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
  }
}

export function* updateProfileCall({ id, payload, callback }) {
  try {
    const result = yield call(API.put, `/api/users/${id}`, payload);

    if (result.status === 200 || result.status === 201) {
      yield put({
        type: types.UPDATE_PROFILE_COMPLETE,
        payload: { user: result.data },
      });
      if (typeof callback === "function") callback(result);
    } else {
      yield put({ type: types.UPDATE_PROFILE_ERROR });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.non_field_errors
    ) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe ha ocurrido un error interno",
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* loginCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/api/login", payload, {
      headers: { noAuth: true },
    });
    if (result.status === 200) {
      if (
        result.data &&
        Array.isArray(result.data.user.roles) &&
        result.data.user.roles.findIndex((item) => item === "ROLE_BRAND") === -1
      ) {
        yield put({ type: types.LOGIN_COMPLETE, payload: result.data });

        if (result.data.user && result.data.user.id) {

          const cart = yield select(cartSelector);
          const cartPayload = {
            items: [],
          };

          if (!cart?.length) {
            yield put(getCartInit()); // updating cart in login
          }

          if (cart?.length) {
            cart.forEach((storeItem) => {
              storeItem.ordenItems.forEach((item) => {
                cartPayload.items.push({
                  quantity: item.quantity,
                  stock: item.stock.id,
                });
              });
            });

            // yield put({ type: UPDATE_CART_INIT });
            const cartResult = yield call(
              API.post,
              `/api/users/${result.data.user.id}/carts/products`,
              cartPayload
            );
            if (
              cartResult.status >= 200 &&
              Array.isArray(cartResult.data.cart)
            ) {
              const data = cartResult.data.cart.map((order) => ({
                ...order,
                ordenItems: order.ordenItems.map((orderItem) => ({
                  ...orderItem,
                  product: {
                    ...orderItem.product,
                    stocks: [orderItem.stock],
                  },
                })),
              }));
              yield put({
                type: UPDATE_CART_SUCCESS,
                payload: data,
              });
            }
          }
        }

        if (typeof callback === "function") callback(result);
        yield put({ type: types.IS_LOGIN_LOADING, payload: {
          isLoggingIn: false,
        }});
      } else if (
        result.data.user.roles.findIndex((item) => item === "ROLE_BRAND") === 0
      ) {
        result.data.user.commerce = true
        yield put({ type: types.LOGIN_COMPLETE, payload: result.data });
        if (typeof callback === "function") callback(result);
        yield put({ type: types.IS_LOGIN_LOADING, payload: {
          isLoggingIn: false,
        }});
      } else {
        yield put({ type: types.LOGIN_ERROR });
        yield put(
          openInfoModal({
            title: "Usuario no válido",
            info: "No puede iniciar sesión con esta cuenta",
          })
        );
      }
    } else {
      yield put({ type: types.LOGIN_ERROR });
    }
  } catch (error) {
    yield put({ type: types.LOGIN_ERROR });
    yield put({ type: UPDATE_CART_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);

    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* sendSMSCall({ callback }) {
  try {
    const result = yield call(
      API.post,
      "/api/access/phone_validation/send_sms/"
    );

    if (result.status === 200 || result.status === 201) {
      yield put({ type: types.SEND_SMS_COMPLETE, payload: result.data });
      if (typeof callback === "function") callback(result);
    } else {
      yield put({ type: types.SEND_SMS_ERROR });
    }
  } catch (error) {
    yield put({ type: types.SEND_SMS_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      yield customToast(error.response.data.message, "error-toast");
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* verifyPhoneCall({ payload, callback }) {
  try {
    const result = yield call(
      API.post,
      "/api/access/phone_validation/validate_code/",
      payload
    );

    if (result.status === 200 || result.status === 201) {
      yield put({ type: types.VERIFY_PHONE_COMPLETE, payload: result.data });
      if (typeof callback === "function") callback(result);
    } else {
      yield put({ type: types.VERIFY_PHONE_ERROR });
    }
  } catch (error) {
    yield put({ type: types.VERIFY_PHONE_ERROR });
    if (!error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }
    if (
      error &&
      error.response &&
      typeof callback === "function" &&
      error.response.status !== 500
    )
      callback(error.response);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      yield customToast(error.response.data.message, "error-toast");
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* googleLoginCall({ payload, history }) {
  try {
    if (payload && payload.tokenId) {
      const result = yield call(API.post, "/api/login/google", {
        token: payload.tokenId,
        fcmToken: payload.fcmToken,
      });
      if (result.data && result.data.token) {
        yield put({ type: types.GOOGLE_LOGIN_COMPLETE, payload: result.data });
        if (result.data.user && result.data.user.id) {

          const cart = yield select(cartSelector);
          const cartPayload = {
            items: [],
          };

          if (!cart?.length) {
            yield put(getCartInit()); // updating cart in login
          }

          if (cart?.length) {
            cart.forEach((storeItem) => {
              storeItem.ordenItems.forEach((item) => {
                cartPayload.items.push({
                  quantity: item.quantity,
                  stock: item.stock.id,
                });
              });
            });

            // yield put({ type: UPDATE_CART_INIT });
            const cartResult = yield call(
              API.post,
              `/api/users/${result.data.user.id}/carts/products`,
              cartPayload
            );
            if (
              cartResult.status >= 200 &&
              Array.isArray(cartResult.data.cart)
            ) {
              const data = cartResult.data.cart.map((order) => ({
                ...order,
                ordenItems: order.ordenItems.map((orderItem) => ({
                  ...orderItem,
                  product: {
                    ...orderItem.product,
                    stocks: [orderItem.stock]
                  },
                })),
              }));
              yield put({
                type: UPDATE_CART_SUCCESS,
                payload: data,
              });
            }
          }
        }
        yield put({ type: types.IS_SOCIAL_LOGIN_LOADING, payload: {
          isLoggingIn: false
        }})
        if (history.location.state !== undefined) {
          history.push("/contact-information/", history.location.state.idStore);
        } else {
          history.push("/catalog");
        }
      } else {
        yield put({ type: types.GOOGLE_LOGIN_ERROR });
      }
    } else {
      yield put({ type: types.GOOGLE_LOGIN_ERROR });
    }
  } catch (error) {
    yield put({ type: types.GOOGLE_LOGIN_ERROR });
    yield put({ type: UPDATE_CART_ERROR });
    if (!error.code && !error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response &&
      error.response.status !== 500
    ) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* facebookLoginCall({ payload, history }) {
  try {
    if (payload && payload.accessToken) {
      const result = yield call(API.post, "/api/login/facebook", {
        token: payload.accessToken,
        fcmToken: payload.fcmToken,
      });
      if (result.data && result.data.token) {
        yield put({
          type: types.FACEBOOK_LOGIN_COMPLETE,
          payload: result.data,
        });
        if (result.data.user && result.data.user.id) {

          const cart = yield select(cartSelector);
          const cartPayload = {
            items: [],
          };

          if (!cart?.length) {
            yield put(getCartInit()); // updating cart in login
          }

          if (cart?.length) {
            cart.forEach((storeItem) => {
              storeItem.ordenItems.forEach((item) => {
                cartPayload.items.push({
                  quantity: item.quantity,
                  stock: item.stock.id,
                });
              });
            });
            const cartResult = yield call(
              API.post,
              `/api/users/${result.data.user.id}/carts/products`,
              cartPayload
            );
            if (
              cartResult.status >= 200 &&
              Array.isArray(cartResult.data.cart)
            ) {
              const data = cartResult.data.cart.map((order) => ({
                ...order,
                ordenItems: order.ordenItems.map((orderItem) => ({
                  ...orderItem,
                  product: {
                    ...orderItem.product,
                    stocks: [orderItem.stock],
                  },
                })),
              }));
              yield put({
                type: UPDATE_CART_SUCCESS,
                payload: data,
              });
            }
          }
        }
        yield put({ type: types.IS_SOCIAL_LOGIN_LOADING, payload: {
          isLoggingIn: false
        }})
        if (history.location.state !== undefined) {
          history.push("/contact-information/", history.location.state.idStore);
        } else {
          history.push("/catalog");
        }
      } else {
        yield put({ type: types.FACEBOOK_LOGIN_ERROR });
      }
    } else {
      yield put({ type: types.FACEBOOK_LOGIN_ERROR });
    }
  } catch (error) {
    yield put({ type: types.FACEBOOK_LOGIN_ERROR });
    if (!error.code && !error.response) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Por favor verifique su conexión a internet",
        })
      );
    }

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response &&
      error.response.status !== 500
    ) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
    if (error && error.response && error.response.status === 500) {
      yield put(
        openInfoModal({
          title: "Error en la solicitud",
          info: "Disculpe, ha ocurrido un error interno",
        })
      );
    }
  }
}

export function* confirmEmailCall({ payload, callback }) {
  try {
    const result = yield call(
      API.post,
      `/api/account-verifications/${payload}`
    );
    if (result.status === 200 || result.status === 201) {
      yield put(
        openInfoModal({
          title: "¡Todo listo! Tu cuenta está confirmada",
          info: "Ahora puedes iniciar sesión en la plataforma",
        })
      );
      if (typeof callback === "function") callback(result);
      yield put({ type: types.CONFIRM_EMAIL_COMPLETE, payload: result.data });
    } else {
      yield put(
        openInfoModal({
          title: "No se pudo confirmar su cuenta",
          info: "El enlace no es valido o ha expirdo",
        })
      );
      yield put({ type: types.CONFIRM_EMAIL_ERROR });
    }
  } catch (error) {
    yield put(
      openInfoModal({
        title: "No se pudo confirmar su cuenta",
        info: "El enlace no es valido o ha expirdo",
      })
    );
    yield put({ type: types.CONFIRM_EMAIL_ERROR });
    if (error && error.response && typeof callback === "function")
      callback(error.response);
  }
}

export function* contactCall({ payload, callback }) {
  try {
    const result = yield call(API.post, `/api/contacts`, payload);
    if (result.status === 200 || result.status === 201) {
      yield put(
        openInfoModal({
          title: "Su información fue enviada",
          info: "Muy pronto nos estaremos comunicando con usted.",
        })
      );
      if (typeof callback === "function") callback(result);
      const success = { success: true };
      yield put({ type: types.CONTACT_INIT_COMPLETE });
      callback(success);
    }
  } catch (error) {
    yield put(
      openInfoModal({
        title: "No se pudo enviar su infirmación",
        info: "Disculpe ha ocurrido un error interno",
      })
    );
    yield put({ type: types.CONFIRM_EMAIL_ERROR });
    if (error && error.response && typeof callback === "function")
      callback(error.response);
  }
}

export function* logoutCall({ payload, token }) {
  try {
    if (payload) {
      yield call(API.delete, `/api/fcm_tokens/${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-unused-vars
    const error = e;
  }finally{
    yield put(updateCart([]));
  }
}

export default function* access() {
  yield takeLatest(types.REGISTER_INIT, registerCall);
  yield takeLatest(types.RECOVER_PASSWORD_INIT, recoverPasswordCall);
  yield takeLatest(types.CHANGE_PASSWORD_INIT, changePasswordCall);
  yield takeLatest(types.SEND_SMS_INIT, sendSMSCall);
  yield takeLatest(types.VERIFY_PHONE_INIT, verifyPhoneCall);
  yield takeLatest(types.UPDATE_PROFILE_INIT, updateProfileCall);
  yield takeLatest(types.LOGIN_INIT, loginCall);
  yield takeLatest(types.GOOGLE_LOGIN_INIT, googleLoginCall);
  yield takeLatest(types.FACEBOOK_LOGIN_INIT, facebookLoginCall);
  yield takeLatest(types.CONFIRM_EMAIL_INIT, confirmEmailCall);
  yield takeLatest(types.RESET_PASSWORD_INIT, resetPasswordCall);
  yield takeLatest(types.CONTACT_INIT, contactCall);
  yield takeLatest(types.LOGOUT, logoutCall);
}
