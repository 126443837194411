import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { primaryColor } from "../../constants";

const Container = styled.div``;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  background-color: #f3f3f3;
  min-height: 160px;
  padding-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
    padding-bottom: 30px;
    p {
      text-align: center;
    }
  }
`;

const Logo = styled.img`
  width: 100%;
  max-height: 40px;
  object-fit: contain;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #404040;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: ${(props) => props.pointer};
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

const SocialButton = styled.div`
  background-color: ${primaryColor};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialNetworksContainer = styled.div`
  display: flex;
`;

const Info = styled.p`
  color: #808080;
  font-weight: 300;
  padding-bottom: 5px;
`;

const CopyRightContainer = styled.div`
  min-height: 60px;
  background-color: #e5e5e5;

  p {
    color: #404040;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const objUrlFacebook = {
  "yoper.cl": "https://www.facebook.com/yoper.cl",
  "yoperapp.com": "https://www.facebook.com/yoper.cl",
  "yoper.com.ar": "https://www.facebook.com/yoper.com.ar",
  "yoper.com.pe": "https://www.facebook.com/yoper.com.pe",
};
const objUrlInstagram = {
  "yoper.cl": "https://www.instagram.com/yoper.cl",
  "yoperapp.com": "https://www.instagram.com/yoper.cl",
  "yoper.com.ar": "https://www.instagram.com/yoper.com.ar",
  "yoper.com.pe": "https://www.instagram.com/yoper.com.pe",
};

const urlHref = window.location.hostname !== "localhost" ? window.location.hostname : "yoper.cl";

export default function Footer() {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <div>
          <Logo src="/assets/images/yoper-header.png" />
        </div>
        <div>
          <Title>Contacto</Title>
          {urlHref === "yoper.cl" ? <Info>contacto@yoper.cl</Info> : null}
          {urlHref === "yoperapp.com" ? <Info>contacto@yoper.cl</Info> : null}
          {urlHref === "yoper.com.ar" ? <Info>contacto@yoper.com.ar</Info> : null}
          {urlHref === "yoper.com.pe" ? <Info>contacto@yoper.com.pe</Info> : null}
        </div>
        <div>
          <Link to="/terms-and-conditions">
            <Title pointer="pointer">Términos y condiciones</Title>
          </Link>
        </div>
        {window.location.pathname !== "/" ? (
          <div>
            <Title
              pointer="pointer"
              onClick={() => {
                history.push("/catalog");
                window.scrollTo(0, 0);
              }}
            >
              Ver tiendas
            </Title>
          </div>
        ) : (
          <div />
        )}
        <SocialNetworksContainer>
          <a href={objUrlFacebook[urlHref]} target="_blank" rel="noopener noreferrer">
            <SocialButton className="opacity">
              <FaFacebookF size={22} color="#fff" />
            </SocialButton>
          </a>
          <a href={objUrlInstagram[urlHref]} target="_blank" rel="noopener noreferrer">
            <SocialButton className="opacity">
              <FaInstagram size={22} color="#fff" />
            </SocialButton>
          </a>
        </SocialNetworksContainer>
      </Content>
      <CopyRightContainer className="total-center">
        <p>
          Copyright <b>Yoper</b>. All rights reserved.
        </p>
      </CopyRightContainer>
    </Container>
  );
}
