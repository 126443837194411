/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import { useField, useFormikContext } from "formik";
import styles from "./styles.module.css";

const Input = ({ label, containerClassName, formClassName, onChange, justNumbers, type, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(props);

    return (
      <div className={`${containerClassName}`}>
        <input
          type={type || 'text'}
          className={`${styles.input} ${formClassName}`}
          placeholder={label}
          {...field}
          {...props}
          onChange={(e) => {
            if (onChange) {
              onChange(e, setFieldValue);
              return
            }
            if (justNumbers) {
              const text = e.target.value.replace(/[^0-9]/g, '');
              setFieldValue(field.name, text);
              return
            }
            field.onChange(e);
          }}
        />
          { meta.touched && meta.error && (
            <div className="error">{meta.error}</div>
          )}
      </div>
    );
  };

export default Input;