import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  background-color: #a0a0a0;
  width: 25px;
  border-radius: 4px;
  div.divider {
    height: 1px;
    background-color: #c0c0c0;
  }
  .button {
    width: 100%;
    height: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
  }
  .disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
  p.quantity {
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
`;

export default function VerticalQuantityButtons({
  quantity,
  onClickMore,
  onClickLess,
  addDisabled,
}) {
  return (
    <Container>
      <div
        role="button"
        tabIndex="-1"
        onClick={!addDisabled ? onClickMore : undefined}
        className={`button${addDisabled ? " disabled" : ""}`}
      >
        +
      </div>
      <div className="divider" />
      <p className="quantity">{quantity}</p>
      <div className="divider" />
      <div role="button" tabIndex="-1" onClick={onClickLess} className="button">
        -
      </div>
    </Container>
  );
}

VerticalQuantityButtons.propTypes = {
  quantity: PropTypes.number.isRequired,
  onClickMore: PropTypes.func,
  onClickLess: PropTypes.func.isRequired,
  addDisabled: PropTypes.bool,
};

VerticalQuantityButtons.defaultProps = {
  onClickMore: undefined,
  addDisabled: false,
};
