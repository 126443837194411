import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;
const ContainerBoxCount = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${(props) => props.backgroundColor || "#757a87"};
  border-top-right-radius: ${(props) => props.borderTopRight};
  border-bottom-right-radius: ${(props) => props.borderBottomRight};
  border-top-left-radius: ${(props) => props.borderTopLeft};
  border-bottom-left-radius: ${(props) => props.borderBottomLeft};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  height: 20px;
  width: 1px;
  background-color: #000000;
`;

const Subtitle = styled.p`
  color: #fff;
  font-size: 16px;
  user-select: none;
`;

const Title = styled.div`
  color: #fff;
  font-size: 11px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #757a87;
`;

export default function QuantityButtons({
  quantity,
  onClickMore,
  onClickLess,
}) {
  return (
    <Container>
      <ContainerBoxCount
        onClick={onClickLess}
        borderTopLeft="5px"
        borderBottomLeft="5px"
        style={{ cursor: "pointer" }}
      >
        <Subtitle>-</Subtitle>
      </ContainerBoxCount>
      <Center>
        <Line />
      </Center>
      <ContainerBoxCount>
        <Title>{quantity}</Title>
      </ContainerBoxCount>
      <Center>
        <Line />
      </Center>
      <ContainerBoxCount
        onClick={onClickMore}
        borderTopRight="5px"
        borderBottomRight="5px"
        style={{ cursor: "pointer" }}
      >
        <Subtitle>+</Subtitle>
      </ContainerBoxCount>
    </Container>
  );
}

QuantityButtons.propTypes = {
  quantity: PropTypes.number.isRequired,
  onClickMore: PropTypes.func.isRequired,
  onClickLess: PropTypes.func.isRequired,
};
