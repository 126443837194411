import styled from "styled-components";

const Select = styled.select`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding || "0px 10px"};
  height: ${(props) => props.height || "40px"};
  border: 1px solid ${(props) => (props.error ? "#F6412D" : "#c0c0c0")};
  border-radius: 8px;
  outline: none;
  font-family: "Poppins",sans-serif;
  color: #4f4f4f;
  font-size: 13px;
  background-color: ${(props) => props.backgroundColor};
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("../../assets/images/arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: red;
  }
  :-ms-input-placeholder {
    color: red;
  }
  @media (max-width: 420px) {
    width: ${(props) => props.widthXs};
    margin: ${(props) => props.marginXs};
  }
`;

export default Select;
