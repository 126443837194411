import React from "react";
import { MdCancel } from "react-icons/md";
import styled from "styled-components";
import Modal from "react-modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "./FormInput";
import Button from "./Button";
import AbsoluteLoader from "./AbsoluteLoader";
import {
  isUpdatingStoreManagerSelector,
  showManagerFormSelector,
} from "../ducks/store/selectors";
import {
  updateStoreManagerInit,
  setShowManagerForm,
} from "../ducks/store/actions";
import { storeSelector } from "../ducks/access/selectors";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    marginRight: "-50%",
    borderRadius: 20,
    boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1)",
    border: "none",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const Container = styled.div`
  padding: 0px;
  padding-bottom: 20px;
  position: relative;
  width: calc(100vw - 20px);
  max-width: 340px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  @media (max-width: 420px) {
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  p.welcome {
    margin: 20px;
    color: #808080;
    text-align: center;
  }
`;

const CloseButton = styled.div`
  background-color: #fff;

  border-radius: 13px;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
`;

Modal.setAppElement("#root");

export default function StoreManagerForm() {
  const dispatch = useDispatch();
  const showManagerForm = useSelector(showManagerFormSelector);
  const store = useSelector(storeSelector);
  const isUpdatingStoreManager = useSelector(isUpdatingStoreManagerSelector);
  const handleSend = (values) => {
    dispatch(
      updateStoreManagerInit({
        ...values,
        id:
          store.dispatchers && store.dispatchers.length
            ? store.dispatchers[0].id
            : undefined,
      })
    );
  };

  const handleClose = () => {
    if (store && store.dispatchers && store.dispatchers.length) {
      dispatch(setShowManagerForm(false));
    }
  };

  return (
    <Modal
      isOpen={showManagerForm}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Formulario de contacto"
    >
      <Container>
        <CloseButton>
          <MdCancel
            onClick={handleClose}
            className="opacity"
            size={22}
            color="#b1bbcd"
          />
        </CloseButton>
        <AbsoluteLoader visible={isUpdatingStoreManager}>
          <Formik
            initialValues={{
              name:
                store.dispatchers && store.dispatchers.length
                  ? store.dispatchers[0].name
                  : "",
              phone:
                store.dispatchers && store.dispatchers.length
                  ? store.dispatchers[0].phone
                  : "",
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(3, "Debe tener mínimo 3 caracteres*")
                .required("El campo es requerido*"),
              phone: Yup.number()
                .typeError("Eso no parece un número de teléfono*")
                .required("El campo es requerido *"),
            })}
            onSubmit={handleSend}
          >
            {({ handleChange }) => {
              return (
                <Form>
                  <Content className="total-center col-cont">
                    <p className="welcome">
                      <b>Bienvenido</b>, ingresa por favor tus datos de
                      contacto, para que los clientes puedan solicitarte
                      atención personalizada y concretar más ventas.
                    </p>
                    <Field
                      component={FormInput}
                      onChange={handleChange}
                      placeholder="Nombre"
                      name="name"
                      maxLength={80}
                      margin="7px 0px"
                      width="280px"
                      maxWidth="calc(100vw - 60px)"
                      type="text"
                    />
                    <Field
                      component={FormInput}
                      onChange={handleChange}
                      placeholder="Whatsapp"
                      name="phone"
                      maxLength={80}
                      margin="7px 0px"
                      width="280px"
                      maxWidth="calc(100vw - 60px)"
                      type="text"
                    />
                    <br />
                    <Button>Guardar</Button>
                  </Content>
                </Form>
              );
            }}
          </Formik>
        </AbsoluteLoader>
      </Container>
    </Modal>
  );
}
